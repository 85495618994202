import React from "react";
import classes from "./Have.module.scss";
import img from "../../../assets/img/TradingSpacePage/3/1.png";
import { useTranslation } from "react-i18next";

const Have = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.have}>
      <div className={classes.haveBody}>
        <div className="container">
          <div className={classes.top}>
            <div className={classes.topRow}>
              <div className={classes.topRowLeft}>
                <div className={[classes.topRowLeftTitle, "title"].join(" ")}>
                  {t('four_types_title')}
                </div>
                <div className={classes.topRowLeftSubtitle}>
                  {t('four_types_text')}
                </div>
              </div>
              <div className={classes.topRowRight}>
                <img src={img} alt="" />
              </div>
            </div>
          </div>
          <div className={classes.botRow}>
            <div className={classes.botRowLeft}>
              <div className={classes.botRowLeftName}>{t('four_types_minimum_deposit')}</div>
              <div className={classes.botRowLeftPrice}>10.0</div>
            </div>
            <div className={classes.botRowRight}>
              <div className={classes.botRowRightName}>{t('four_types_comission')}</div>
              <div className={classes.botRowRightPrice}>0.0</div>
            </div>
          </div>
          <div className={classes.botRow}>
            <div className={classes.botRowLeft}>
              <div className={classes.botRowLeftName}>
                {t('four_types_maximal_leverage')}
              </div>
              <div className={classes.botRowLeftPrice}>2000</div>
            </div>
            <div className={classes.botRowRight}>
              <div className={classes.botRowRightName}>{t('four_types_spread')}</div>
              <div className={classes.botRowRightPrice}>{t('four_types__variable')}</div>
            </div>
          </div>
          <div className={classes.botRow}>
            <div className={classes.botRowLeft}>
              <div className={classes.botRowLeftName}>{t('four_types_minimum_lot')}</div>
              <div className={classes.botRowLeftPrice}>0.01</div>
            </div>
            <div className={classes.botRowRight}>
              <div className={classes.botRowRightName}>{t('four_types_decimals')}</div>
              <div className={classes.botRowRightPrice}>5 {t('four_types__decimals')}</div>
            </div>
          </div>
          <div className={classes.botRow}>
            <div className={classes.botRowLeft}>
              <div className={classes.botRowLeftName}>{t('four_types_maximum_lot')}</div>
              <div className={classes.botRowLeftPrice}>100.0</div>
            </div>
            <div className={classes.botRowRight}>
              <div className={classes.botRowRightName}>{t('four_types_scalping_allowed')}</div>
              <div className={classes.botRowRightPrice}>{t('four_types__yes')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Have;
