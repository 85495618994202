import React from "react";
import classes from './Banner.module.scss';
import light_01 from '../../../assets/img/AboutPage/banner_light_01.png';
import light_02 from '../../../assets/img/AboutPage/banner_light_02.png';
import bull from '../../../assets/img/AboutPage/banner_bull.png';
import bear from '../../../assets/img/AboutPage/banner_bear.png';
import together from '../../../assets/img/AboutPage/banner_together.png';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Banner = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className={classes.banner}>
            <div className="container">
                <div className={classes.content}>
                    <div className={[classes.header, 'header-default'].join(' ')}>
                        {t('about_us')}
                    </div>
                    <div className={classes.second}>
                        {t('about_banner_title')}
                    </div>
                    <div className={[classes.text, 'text-default'].join(' ')}>
                        {t('about_banner_text')}
                    </div>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.btn, 'btn-default'].join(' ')}>
                        {t('start_trading')}
                    </a>
                    <div className={classes.animals}>
                        <img className={classes.bull} src={bull} alt='' />
                        <img className={classes.bear} src={bear} alt='' />
                        <img className={classes.tog} src={together} alt='' />
                    </div>
                </div>
            </div>
            <img className={classes.light_01} src={light_01} alt='' />
            <img className={classes.light_02} src={light_02} alt='' />
        </div>
    );
};

export default Banner;
