import React, { useState } from "react";
import classes from './Footer.module.scss';
import coin_01 from '../../../assets/img/Footer/coin_01.png';
import coin_02 from '../../../assets/img/Footer/coin_02.png';
import coin_03 from '../../../assets/img/Footer/coin_03.png';
import arrow from '../../../assets/img/Footer/arrow.svg';
import logo from '../../../assets/img/header/logo.svg';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const { hostname } = document.location;
    return (
        <div className={classes.footer}>
            <div className="container">
                <div className={classes.contactUs}>
                    <div className={[classes.header, 'header-default'].join(' ')}>
                        <Trans
                            components={{ span: <span></span> }}
                        >{t('footer_contact_us')}</Trans>
                    </div>
                    <div className={classes.form}>
                        <input
                            type='text'
                            placeholder='Full name'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <input
                            type='text'
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <input
                            type='text'
                            placeholder='Phone'
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                        />
                        <div className={classes.btn}>
                            <div className={'btn-default'}>
                                {t('footer__send')}
                                <img src={arrow} alt='' />
                            </div>
                        </div>
                        <img className={classes.coin_01} src={coin_01} alt='' />
                        <img className={classes.coin_02} src={coin_02} alt='' />
                        <img className={classes.coin_03} src={coin_03} alt='' />
                    </div>
                </div>
                <div className={classes.under}>
                    <div className={classes.grid_01}>
                        <div className={classes.logo}>
                            <img src={logo} alt='' />
                        </div>
                        <a href="tel:+441519479680" className={classes.num}>
                            +441519479680
                        </a>
                        <a href={`mailto:support@${hostname}`} className={classes.mail}>
                            support@{hostname}
                        </a>
                    </div>
                    <div className={classes.grid_02}>
                        <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank" className={classes.item}>
                            {t('footer_terms_conditions')}
                        </a>
                        <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className={classes.item}>
                            {t('footer_privacy_policy')}
                        </a>
                        <a  href="/docs/AML_POLICY.pdf" target="_blank" className={classes.item}>
                            {t('footer_aml_policy')}
                        </a>
                        {/* <div className={classes.item}>
                            {t('footer_legal_docs')}
                        </div> */}
                    </div>
                    <div className={classes.grid_03}>
                        <div className={classes.item}>
                            <Link to='/trading-space'>
                                {t('trading_space')}
                            </Link>
                        </div>
                        <div className={classes.item}>
                            <Link to='/trading-conditions'>
                                {t('trading_conditions')}
                            </Link>
                        </div>
                        <div className={classes.item}>
                            <Link to='/about'>
                                {t('about_us')}
                            </Link>
                        </div>
                    </div>
                    <div className={classes.grid_04}>
                        <div className={classes.item}>
                            {t('footer_info')}
                        </div>
                        {/* <div className={classes.item}>
                            General information: info@apexplatform.com
                        </div>
                        <div className={classes.item}>
                            Technical support: support@help.apexplatform.com
                        </div> */}
                    </div>
                    <div className={classes.grid_05}>
                        <div className={classes.header}>
                            {t('footer_disclaimer')}
                        </div>
                        <div className={classes.text}>
                            {t('footer_disclaimer_text')}
                        </div>
                    </div>
                    <div className={classes.grid_06}>
                        <div className={classes.header}>
                            {t('footer_about')}
                        </div>
                        <div className={classes.text}>
                            {t('footer_about_text')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
