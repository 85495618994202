import React from "react";
import classes from "./Study.module.scss";
import img1 from "../../../assets/img/MainPage/Study/1.png";
import { useTranslation } from "react-i18next";
const Study = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.study, ""].join(" ")}>
      <div className={[classes.studyBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.studyRow, ""].join(" ")}>
            <div className={[classes.studyRowLeft, ""].join(" ")}>
              <h2
                className={[classes.studyRowLeftTitle, "header-default"].join(
                  " "
                )}
              >
                {t("new_study_1")}
              </h2>
              <p
                className={[classes.studyRowLeftText, "text-default"].join(" ")}
              >
                {t("new_study_2")}
              </p>
              <p
                className={[classes.studyRowLeftSubText, "text-default"].join(
                  " "
                )}
              >
                {t("new_study_3")}
              </p>
            </div>
            <div className={[classes.studyRowRight, ""].join(" ")}>
              <img src={img1} alt="Study on Apex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Study;
