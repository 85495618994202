import React, { useState } from "react";
import classes from "./Spreads.module.scss";
import bg from "../../../assets/img/ConditionsPage/2/1.png";
import img1 from "../../../assets/img/ConditionsPage/2/2.png";
import img2 from "../../../assets/img/ConditionsPage/2/3.png";
import img3 from "../../../assets/img/ConditionsPage/2/4.png";
import img4 from "../../../assets/img/ConditionsPage/2/5.png";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Spreads = () => {
  const { t } = useTranslation();
  const [animH, setAnimH] = useState(false);
  const defaultOption = Cookies.get('i18next');
  return (
    <div className={classes.spreads}>
      <div className={classes.spreadsBody}>
        <div className="container">
          <div className={classes.spreadsWrapper}>
            <div className={[classes.spreadsTitle, "title"].join(" ")}>
              {t('spreads_title')}
            </div>
            <div className={classes.spreadsSubtitle}>{t('spreads_subtitle')}</div>
            <div className={classes.spreadsText}>
              {t('spreads_text_01')} {t('spreads_text_02')}
            </div>
            <a
              href={`https://user.apexplatform.info/signup?lang=${defaultOption}`}
              className={[classes.spreadsBtn, 'btn-default'].join(' ')}
              onMouseEnter={() => {
                setAnimH(true);
              }}
              onMouseLeave={() => {
                setAnimH(false);
              }}
            >
              {t('start_trading')}
            </a>
            <div className={classes.spreadsWrapperPlanet}>
              <img src={bg} alt="" />
            </div>
            <div
              className={
                animH
                  ? [classes.spreadsWrapperZipper1, classes.active].join(" ")
                  : classes.spreadsWrapperZipper1
              }
            >
              <img src={img1} alt="" />
            </div>
            <div
              className={
                animH
                  ? [classes.spreadsWrapperZipper2, classes.active].join(" ")
                  : classes.spreadsWrapperZipper2
              }
            >
              <img src={img2} alt="" />
            </div>
            <div
              className={
                animH
                  ? [classes.spreadsWrapperZipper3, classes.active].join(" ")
                  : classes.spreadsWrapperZipper3
              }
            >
              <img src={img3} alt="" />
            </div>
            <div
              className={
                animH
                  ? [classes.spreadsWrapperZipper4, classes.active].join(" ")
                  : classes.spreadsWrapperZipper4
              }
            >
              <img src={img4} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spreads;
