import React, { useEffect, useRef } from "react";
import Hours from "../../components/ConditionsPage/Hours/Hours";
import Industry from "../../components/ConditionsPage/Industry/Industry";
import Invest from "../../components/ConditionsPage/Invest/Invest";
import Spreads from "../../components/ConditionsPage/Spreads/Spreads";
import Standart from "../../components/ConditionsPage/Standart/Standart";

import { initAnimation } from "../../mixins/animationMethods";
import { useLocation } from "react-router-dom";

const ConditionsPage = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    if (ref !== null && document.documentElement.clientWidth > 1200) {
      initAnimation(ref, 5);
    }
  }, []);

  return (
    <div ref={ref} className="ovf-hidden">
      <Invest />

      <div id="animBlock_1">
        <Spreads />
      </div>
      <div id="animBlock_2">
        <Industry />
      </div>
      <div id="animBlock_3">
        <Standart />
      </div>
      <div id="animBlock_4">
        <Hours />
      </div>
    </div>
  );
};

export default ConditionsPage;
