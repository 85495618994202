import React, { useState } from "react";
import classes from "./Hours.module.scss";
import tab1_1 from "../../../assets/img/ConditionsPage/5/1/1.png";
import tab1_2 from "../../../assets/img/ConditionsPage/5/1/2.png";
import tab2_1 from "../../../assets/img/ConditionsPage/5/2/1.png";
import tab2_2 from "../../../assets/img/ConditionsPage/5/2/2.png";
import tab2_3 from "../../../assets/img/ConditionsPage/5/2/3.png";
import tab2_4 from "../../../assets/img/ConditionsPage/5/2/4.png";
import tab2_5 from "../../../assets/img/ConditionsPage/5/2/5.png";
import tab2_6 from "../../../assets/img/ConditionsPage/5/2/6.png";
import tab2_7 from "../../../assets/img/ConditionsPage/5/2/7.png";
import tab2_8 from "../../../assets/img/ConditionsPage/5/2/8.png";
import tab2_9 from "../../../assets/img/ConditionsPage/5/2/9.png";
import tab2_10 from "../../../assets/img/ConditionsPage/5/2/10.png";
import tab2_11 from "../../../assets/img/ConditionsPage/5/2/11.png";
import tab2_12 from "../../../assets/img/ConditionsPage/5/2/12.png";
import tab2_13 from "../../../assets/img/ConditionsPage/5/2/13.png";
import tab2_14 from "../../../assets/img/ConditionsPage/5/2/14.png";
import tab2_15 from "../../../assets/img/ConditionsPage/5/2/15.png";
import tab2_16 from "../../../assets/img/ConditionsPage/5/2/16.png";
import tab2_17 from "../../../assets/img/ConditionsPage/5/2/17.png";
import tab2_18 from "../../../assets/img/ConditionsPage/5/2/18.png";
import tab2_19 from "../../../assets/img/ConditionsPage/5/2/19.png";
import tab4_1 from "../../../assets/img/ConditionsPage/5/4/1.png";
import tab4_2 from "../../../assets/img/ConditionsPage/5/4/2.png";
import tab4_3 from "../../../assets/img/ConditionsPage/5/4/3.png";
import tab4_4 from "../../../assets/img/ConditionsPage/5/4/4.png";
import { useTranslation } from "react-i18next";

const Hours = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 11,
      title: "trading_hours_tab_01",
      topRow: ["trading_hours_col_01", "trading_hours_col_02", "trading_hours_col_03"],
      active: true,
      tabsContent: [
        {
          id: 1,
          icon: tab1_1,
          contents: [`FX`, `00:00 — 23:58`, `00:01 — 23:58`],
        },
        {
          id: 2,
          icon: tab1_2,
          contents: [
            `AUD/CNH
`,
            `00:00 — 23:57
`,
            `00:01 — 23:57
`,
          ],
        },
      ],
    },
    {
      id: 22,
      title: "trading_hours_tab_02",
      topRow: ["trading_hours_col_01", "trading_hours_col_02", "trading_hours_col_03"],
      active: false,
      tabsContent: [
        {
          id: 1,
          icon: tab2_1,
          contents: [
            `Gold
`,
            `M-T: 01:01-23:58 
F: 01:01-23:57


`,
            `M-T: 01:01-23:58
F: 01:01-23:57
`,
          ],
        },
        {
          id: 2,
          icon: tab2_2,
          contents: [
            `Silver


`,
            `M-T: 01:01-23:58
F: 01:01-23:57



`,
            `M-T: 01:01-23:58
F: 01:01-23:57


`,
          ],
        },
        {
          id: 3,
          icon: tab2_3,
          contents: [
            `Copper



`,
            `M-F: 01:00-24:00
F: 01:01-23:57


`,
            `M-F: 01:00-24:00
F: 01:01-23:57
`,
          ],
        },
        {
          id: 4,
          icon: tab2_4,
          contents: [
            `XPTUSD

`,
            `M-F 01:00-24:00




`,
            `M-F 01:00-24:00




`,
          ],
        },
        {
          id: 5,
          icon: tab2_5,
          contents: [
            `XPDUSD
`,
            `M-F 01:00-24:00



`,
            `M-F 01:00-24:00

`,
          ],
        },
        {
          id: 6,
          icon: tab2_6,
          contents: [`NG`, `M-F 01:00-24:00`, `M-F 01:00-24:00`],
        },
        {
          id: 7,
          icon: tab2_7,
          contents: [
            `Gas


`,
            `M-F 01:00-24:00



`,
            `M-F 01:00-24:00



`,
          ],
        },
        {
          id: 8,
          icon: tab2_8,
          contents: [
            `GASOIL-C


`,
            `M 01:00-24:00
T-F 03:00-24:00



`,
            `M 01:00-24:00
T-F 03:00-24:00



`,
          ],
        },
        {
          id: 9,
          icon: tab2_9,
          contents: [
            `CL-OIL
`,
            `M-F 01:00-24:00

`,
            `M-F 01:00-24:00

`,
          ],
        },
        {
          id: 10,
          icon: tab2_10,
          contents: [
            `USOUSD


`,
            `M-F 01:00-24:00`,
            `M-F 01:00-24:00`,
          ],
        },
        {
          id: 11,
          icon: tab2_11,
          contents: [
            `UKOUSD


`,
            `M 01:00-24:00
T-F 00:00-01:00; 03:00-24:00



`,
            `M 01:00-24:00
T-F 00:00-01:00; 03:00-24:00



`,
          ],
        },
        {
          id: 12,
          icon: tab2_12,
          contents: [
            `UKOUSDft


`,
            `M 01:00-24:00
T-T 00:00-01:00; 03:00-24:00
F 00:00-01:00; 03:00-24:00
`,
            `M 01:00-24:00
T-T 00:00-01:00; 03:00-24:00
F 00:00-01:00; 03:00-24:00
`,
          ],
        },
        {
          id: 13,
          icon: tab2_13,
          contents: [
            `Cocoa-C

`,
            `M-F 11:45-20:30
`,
            `M-F 11:45-20:30
`,
          ],
        },
        {
          id: 14,
          icon: tab2_14,
          contents: [
            `Coffee-C



`,
            `M-F 11:15-20:30




`,
            `M-F 11:15-20:30




`,
          ],
        },
        {
          id: 15,
          icon: tab2_15,
          contents: [
            `Cotton-C



`,
            `M-F 04:00-21:20




`,
            `M-F 04:00-21:20




`,
          ],
        },
        {
          id: 16,
          icon: tab2_16,
          contents: [
            `Orange-C



`,
            `M-F 15:00-21:00




`,
            `M-F 15:00-21:00




`,
          ],
        },
        {
          id: 17,
          icon: tab2_17,
          contents: [
            `Sugar-C
`,
            `M-F 10:30-20:00

`,
            `M-F 10:30-20:00

`,
          ],
        },
        {
          id: 18,
          icon: tab2_18,
          contents: [
            `Soybean-C





`,
            `M-F 03:00-15:45; 16:30-21:20







`,
            `M-F 03:00-15:45; 16:30-21:20







`,
          ],
        },
        {
          id: 19,
          icon: tab2_19,
          contents: [
            `Wheat-C





`,
            `M-F 03:00-15:45; 16:30-21:20







`,
            `M-F 03:00-15:45; 16:30-21:20







`,
          ],
        },
      ],
    },
    {
      id: 33,
      title: "trading_hours_tab_03",
      topRow: ["trading_hours_col_01", "trading_hours_col_02", "trading_hours_col_03"],
      active: false,// eslint-disable-next-line
      tabsContent: [
        {
          id: 1,
          icon: "",
          contents: [
            `CHINA50`,
            `M-F 01:00-03:55
04:00-11:55; 12:00-24:00


`,
            `M-F 01:00-03:55
04:00-11:55; 12:00-24:00
`,
          ],
        },
        {
          id: 2,
          icon: "",
          contents: [
            `CHINA50ft

`,
            `M-F 04:00-11:30 12:00-23:45



`,
            `M-F 04:00-11:30 12:00-23:45


`,
          ],
        },
        {
          id: 3,
          icon: "",
          contents: [
            `DAX40




`,
            `M-T 03:15-24:00 F 03:15-23:00



`,
            `M-T 03:15-24:00 F 03:15-23:00

`,
          ],
        },
        {
          id: 4,
          icon: "",
          contents: [
            `DAX40ft


`,
            `M-T 03:15-24:00 
F 03:15-23:00





`,
            `M-T 03:15-24:00 
F 03:15-23:00





`,
          ],
        },
        {
          id: 5,
          icon: "",
          contents: [
            `DJ30

`,
            `M-F 01:00-24:00



`,
            `M-F 01:00-24:00

`,
          ],
        },
        {
          id: 6,
          icon: "",
          contents: [
            `DJ30ft




`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        {
          id: 7,
          icon: "",
          contents: [
            `ES35








`,
            `M-F 09:00-21:00






`,
            `M-F 09:00-21:00






`,
          ],
        },
        ,
        {
          id: 8,
          icon: "",
          contents: [
            `EU50








`,
            `M-F 01:00-24:00










`,
            `M-F 01:00-24:00










`,
          ],
        },
        ,
        {
          id: 9,
          icon: "",
          contents: [
            `FRA40






`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 10,
          icon: "",
          contents: [
            `FTSE100








`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 11,
          icon: "",
          contents: [
            `FTSE100ft








`,
            `M 01:05-24:00 
T–T 01:00-24:00
F 01:00-23:00






`,
            `M 01:05-24:00 
T–T 01:00-24:00
F 01:00-23:00






`,
          ],
        },
        ,
        {
          id: 12,
          icon: "",
          contents: [
            `HK50








`,
            `M-F 04:15-07:00
08:00-11:30 12:15-22:00






`,
            `M-F 04:15-07:00
08:00-11:30 12:15-22:00






`,
          ],
        },
        ,
        {
          id: 13,
          icon: "",
          contents: [
            `HK50ft







`,
            `M-F 04:15-07:00
08:00-11:30; 12:15-22:00






`,
            `M-F 04:15-07:00
08:00-11:30; 12:15-22:00






`,
          ],
        },
        ,
        {
          id: 14,
          icon: "",
          contents: [
            `INDIA50









`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 15,
          icon: "",
          contents: [
            `NAS100









`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 16,
          icon: "",
          contents: [
            `NAS100ft









`,
            `M-T 01:05-24:00 
F 01:00-23:00











`,
            `M-T 01:05-24:00 
F 01:00-23:00











`,
          ],
        },
        ,
        {
          id: 17,
          icon: "",
          contents: [
            `Nikkei225






`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 18,
          icon: "",
          contents: [
            `SA40










`,
            `M-F 09:30-18:30












`,
            `M-F 09:30-18:30












`,
          ],
        },
        ,
        {
          id: 19,
          icon: "",
          contents: [
            `SP500










`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 20,
          icon: "",
          contents: [
            `SP500ft





`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 21,
          icon: "",
          contents: [
            `SPI200










`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 22,
          icon: "",
          contents: [
            `US2000







`,
            `M-F 01:00-24:00





`,
            `M-F 01:00-24:00





`,
          ],
        },
        ,
        {
          id: 23,
          icon: "",
          contents: [
            `USDX











`,
            `M 01:00-24:00 
T-T 03:00-24:00
F 03:00-23:00













`,
            `M 01:00-24:00 
T-T 03:00-24:00
F 03:00-23:00













`,
          ],
        },
        ,
        {
          id: 24,
          icon: "",
          contents: [
            `VIX





`,
            `M-T 01:00-24:00 
F 01:00-23:15






`,
            `M-T 01:00-24:00 
F 01:00-23:15






`,
          ],
        },
      ],
    },
    {
      id: 44,
      title: "trading_hours_tab_04",
      topRow: ["trading_hours_col_01", "trading_hours_col_02", "trading_hours_col_03"],
      active: false,
      tabsContent: [
        {
          id: 1,
          icon: tab4_1,
          contents: [
            `AU Shares
`,
            `M-F 03:00-09:00


`,
            `M-F 03:00-09:00



`,
          ],
        },
        {
          id: 2,
          icon: tab4_2,
          contents: [
            `EU Shares





`,
            `M-F 10:00-18:30








`,
            `M-F 10:00-18:30


`,
          ],
        },
        {
          id: 3,
          icon: tab4_3,
          contents: [
            `UK Shares



`,
            `M-F 10:00-18:30


`,
            `M-F 10:00-18:30
`,
          ],
        },
        {
          id: 4,
          icon: tab4_4,
          contents: [
            `US Shares





`,
            `M-F 16:30-23:00








`,
            `M-F 16:30-23:00



`,
          ],
        },
      ],
    },
  ]);

  return (
    <div className={classes.hours}>
      <div className={classes.hoursBody}>
        <div className="container">
          <div className={[classes.hoursTitle, "title"].join(" ")}>
            {t('trading_hours_title')}
          </div>
          <div className={classes.hoursSubtitle}>
            {t('trading_hours_text')}
          </div>
          <div className={classes.hoursText}>
            <p>
              {t('trading_hours_text_01')} {t('trading_hours_text_02')}
            </p>
            <p>
              <b>
                {t('trading_hours_text_03')}
              </b>
            </p>
            <p>
              <b>
                {t('trading_hours_text_04')}
              </b>
            </p>
          </div>
          <div className={classes.standartTabs}>
            {tabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setTabs(
                      tabs.map((i) => {
                        if (tab.id === i.id) {
                          return { ...i, active: true };
                        } else {
                          return { ...i, active: false };
                        }
                      })
                    );
                  }}
                  className={
                    tab.active
                      ? [classes.standartTabsTab, classes.active].join(" ")
                      : classes.standartTabsTab
                  }
                >
                  {t(tab.title)}
                </div>
              );
            })}
          </div>
          {tabs.map((tab, idx) => {
            return (
              <div
                key={idx}
                className={
                  tab.active
                    ? [classes.standartContent, classes.active].join(" ")
                    : classes.standartContent
                }
              >
                <div className={classes.standartContentTop}>
                  {tab.topRow.map((topI, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.standartContentTopColumn}
                      >
                        {t(topI)}
                      </div>
                    );
                  })}
                </div>
                {tab.tabsContent.map((tabsContentItem, index) => {
                  return (
                    <div
                      className={classes.standartContentRow}
                      key={index}
                    >
                      <img src={tabsContentItem.icon} alt="" />
                      {tabsContentItem.contents.map((tabsContentItemT, index) => {
                        return (
                          <div
                            key={index}
                            className={classes.standartContentRowColumn}
                          >
                            <div className={classes.wrapText}>
                              {tabsContentItemT}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hours;
