import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * @param {ref} ref to main div in page 
 * @param {number} count of divs that has animation 
 */
export const initAnimation = (ref, count) => {
    gsap.registerPlugin(ScrollTrigger);
    const element = ref.current;
    for (let i = 0; i < count; i++)
        gsap.fromTo(
            element.querySelector(`#animBlock_${i}`),
            { 
                opacity: 0, 
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
                duration: .8,
                scrollTrigger: {
                    trigger: element.querySelector(`#animBlock_${i}`),
                    start: "top bottom",
                }
            }
        );
}