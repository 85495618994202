import React, { useState } from "react";
import classes from "./Standart.module.scss";
import tab1_1 from "../../../assets/img/ConditionsPage/4/1/1.png";
import tab1_2 from "../../../assets/img/ConditionsPage/4/1/2.png";
import tab1_3 from "../../../assets/img/ConditionsPage/4/1/3.png";
import tab1_4 from "../../../assets/img/ConditionsPage/4/1/4.png";
import tab1_5 from "../../../assets/img/ConditionsPage/4/1/5.png";
import tab1_6 from "../../../assets/img/ConditionsPage/4/1/6.png";
import tab1_7 from "../../../assets/img/ConditionsPage/4/1/7.png";
import tab1_8 from "../../../assets/img/ConditionsPage/4/1/8.png";
import tab2_1 from "../../../assets/img/ConditionsPage/4/2/1.png";
import tab2_2 from "../../../assets/img/ConditionsPage/4/2/2.png";
import tab2_3 from "../../../assets/img/ConditionsPage/4/2/3.png";
import tab2_4 from "../../../assets/img/ConditionsPage/4/2/4.png";
import tab2_5 from "../../../assets/img/ConditionsPage/4/2/5.png";
import tab2_6 from "../../../assets/img/ConditionsPage/4/2/6.png";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Cookies from 'js-cookie';

const Standart = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  const [tabs, setTabs] = useState([
    {
      id: 11,
      title: "comissions_standart",
      topRow: [
        "comissions_col_01",
        "comissions_col_02",
        "comissions_col_03",
      ],
      active: true,
      tabsContent: [
        {
          id: 1,
          icon: tab1_1,
          contents: [
            `AUD Australian Dollar`,
            {
              text_01: 'AUD $0.03 (', text_02: '$0.06)'
            },
            {
              text_01: 'AUD $3 (', text_02: '$6)'
            },
          ],
        },
        {
          id: 2,
          icon: tab1_2,
          contents: [
            `USD US Dollar`,
            {
              text_01: 'USD $0.03 (', text_02: '$0.06)'
            },
            {
              text_01: 'USD $3 (', text_02: '$6)'
            },
          ],
        },
        {
          id: 3,
          icon: tab1_3,
          contents: [
            `GBP Pound Sterling`,
            {
              text_01: 'GBP £0.02 (', text_02: '£0.04)'
            },
            {
              text_01: 'GBP £2 (', text_02: '£4)'
            },
          ],
        },
        {
          id: 4,
          icon: tab1_4,
          contents: [
            `EUR Euro`,
            {
              text_01: 'EUR €0.025 (', text_02: '€0.05)'
            },
            {
              text_01: 'EUR €2.5 (', text_02: '€5)'
            },
          ],
        },
        {
          id: 5,
          icon: tab1_5,
          contents: [
            `SGD Singapore Dollar`,
            {
              text_01: 'SGD $0.035 (', text_02: '$0.07)'
            },
            {
              text_01: 'SGD $3.50 (', text_02: '$7)'
            },
          ],
        },
        {
          id: 6,
          icon: tab1_6,
          contents: [
            `JPY Japanese Yen`,
            {
              text_01: 'JPY ¥2.75 (', text_02: '¥5.50)'
            },
            {
              text_01: 'JPY ¥275 (', text_02: '¥550)'
            },
          ],
        },
        {
          id: 7,
          icon: tab1_7,
          contents: [
            `NZD New Zealand Dollar`,
            {
              text_01: 'ANZD $0.0325 (', text_02: '$0.065)'
            },
            {
              text_01: 'NZD $3.25 (', text_02: '$6.50)'
            },
          ],
        },
        {
          id: 8,
          icon: tab1_8,
          contents: [
            `CAD Canadian Dollar`,
            {
              text_01: 'CAD $0.03 (', text_02: '$0.06)'
            },
            {
              text_01: 'CAD $3 (', text_02: '$6)'
            },
          ],
        },
      ],
    },
    {
      id: 22,
      title: "comissions_advanced_pro",
      topRow: [
        "comissions_col_01",
        "comissions_col_02",
        "comissions_col_03",
      ],
      active: false,
      tabsContent: [
        {
          id: 1,
          icon: tab2_1,
          contents: [
            `AUD Australian Dollar`,
            {
              text_01: 'AUD $0.02 (', text_02: '$0.04)'
            },
            {
              text_01: 'AUD $2 (', text_02: '$4)'
            },
          ],
        },
        {
          id: 2,
          icon: tab2_2,
          contents: [
            `USD US Dollar`,
            {
              text_01: 'USD $0.15 (', text_02: '$0.03)'
            },
            {
              text_01: 'USD $1,5 (', text_02: '$3)'
            },
          ],
        },
        {
          id: 3,
          icon: tab2_3,
          contents: [
            `GBP Pound Sterling`,
            {
              text_01: 'GBP £0.01 (', text_02: '£0.02)'
            },
            {
              text_01: 'GBP £1 (', text_02: '£2)'
            },
          ],
        },
        {
          id: 4,
          icon: tab2_4,
          contents: [
            `EUR Euro`,
            {
              text_01: 'EUR €0.015 (', text_02: '€0.03)'
            },
            {
              text_01: 'EUR €1.5 (', text_02: '€3)'
            },
          ],
        },
        {
          id: 5,
          icon: tab2_5,
          contents: [
            `CAD Canadian Dollar`,
            {
              text_01: 'CAD $0.02 (', text_02: '$0.04)'
            },
            {
              text_01: 'CAD $2 (', text_02: '$4)'
            },
          ],
        },
        {
          id: 6,
          icon: tab2_6,
          contents: [
            `JPY Japanese Yen`,
            {
              text_01: 'JPY ¥1.75 (', text_02: '¥3.50)'
            },
            {
              text_01: 'JPY ¥175 (', text_02: '¥350)'
            },
          ],
        },
      ],
    },
  ]);

  return (
    <div className={classes.standart}>
      <div className={classes.standartBody}>
        <div className="container">
          <div className={[classes.standartTitle, "title"].join(" ")}>
            {t('comissions_title')}
          </div>
          <div className={classes.standartTabs}>
            {tabs.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setTabs(
                      tabs.map((i) => {
                        if (tab.id === i.id) {
                          return { ...i, active: true };
                        } else {
                          return { ...i, active: false };
                        }
                      })
                    );
                  }}
                  className={
                    tab.active
                      ? [classes.standartTabsTab, classes.active].join(" ")
                      : classes.standartTabsTab
                  }
                >
                  {/* {tab.title} */}
                  {t(tab.title)}
                </div>
              );
            })}
          </div>
          {tabs.map((tab, idx) => {
            return (
              <div
                key={idx}
                className={
                  tab.active
                    ? [classes.standartContent, classes.active].join(" ")
                    : classes.standartContent
                }
              >
                <div className={classes.standartContentTop}>
                  {tab.topRow.map((topI, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.standartContentTopColumn}
                      >
                        <Trans>{t(topI)}</Trans>
                      </div>
                    );
                  })}
                </div>
                {tab.tabsContent.map((tabsContentItem, index) => {
                  return (
                    <div
                      className={classes.standartContentRow}
                      key={index}
                    >
                      <img src={tabsContentItem.icon} alt="" />
                      {tabsContentItem.contents.map((tabsContentItemT, index) => {
                        if (index === 0) {
                          return (
                            <div
                              key={index}
                              className={classes.standartContentRowColumn}
                            >
                              {tabsContentItemT}
                            </div>
                          );
                        }
                        return (
                          <div
                            key={index}
                            className={classes.standartContentRowColumn}
                          >
                            {t('comissions__round_turn', tabsContentItemT)}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className={classes.standartStart}>
            <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className="btn-default">
              {t('start_trading')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Standart;
