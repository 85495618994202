import React, { useState } from "react";
import classes from './WhoWeAre.module.scss';
import coin_01 from '../../../assets/img/AboutPage/who_coin_01.png';
import coin_02 from '../../../assets/img/AboutPage/who_coin_02.png';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const WhoWeAre = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const defaultOption = Cookies.get('i18next');
    return (
        <div className="container">
            <div className={classes.whoWeAre}>
                <div className={classes.content}>
                    <div className={[classes.header, 'header-default'].join(' ')}>
                        {t('who_we_title')}
                    </div>
                    <div className={[classes.text, 'text-default'].join(' ')}>
                        {t('who_we_text_01')} {t('who_we_text_02')} {t('who_we_text_03')} {t('who_we_text_04')}
                    </div>
                    <a
                        href={`https://user.apexplatform.info/signup?lang=${defaultOption}`}
                        className={[classes.btn, 'btn-default'].join(' ')}
                        onMouseEnter={() => {
                            if (window.innerWidth > 992)
                                setActive(true);
                        }}
                        onMouseLeave={() => {
                            if (window.innerWidth > 992)
                                setActive(false)
                        }}
                    >
                        {t('start_trading')}
                    </a>
                </div>
                <div className={classes.images}>
                    <img
                        className={
                            active ? [classes.coin_01, classes.active].join(' ')
                                : classes.coin_01
                        }
                        src={coin_01}
                        alt=''
                    />
                    <img
                        className={
                            active ? [classes.coin_02, classes.active].join(' ')
                                : classes.coin_02
                        }
                        src={coin_02}
                        alt=''
                    />
                </div>
            </div>
        </div>
    );
};

export default WhoWeAre;
