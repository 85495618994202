import React from "react";
import classes from './ShortOverview.module.scss';
import girl from '../../../assets/img/MainPage/overview_girl.png';
import coin_01 from '../../../assets/img/MainPage/overview_coin_01.png';
import coin_02 from '../../../assets/img/MainPage/overview_coin_02.png';
import { useTranslation } from "react-i18next";

const ShortOverview = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.shortOverview}>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    {t('short_overview_title')}
                </div>
                <div className={classes.content}>
                    <div className={classes.text}>
                        <div className={[classes.par, 'text-default'].join(' ')}>
                            {t('short_overview_text_01')}
                        </div>
                        <div className={[classes.par2, 'text-default'].join(' ')}>
                            {t('short_overview_text_02')} {t('short_overview_text_03')}
                        </div>
                    </div>
                    <div className={[classes.image, 'no-select'].join(' ')}>
                        <img className={classes.girl} src={girl} alt='' />
                        <img className={classes.coin_01} src={coin_01} alt='' />
                        <img className={classes.coin_02} src={coin_02} alt='' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShortOverview;
