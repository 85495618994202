import React from "react";
import classes from "./First.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const First = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstTitle}>{t('trading_space_banner_title')}</div>
          <div className={classes.firstSubtitle}>
            {t('trading_space_banner_subtitle')}
          </div>
          <div className={classes.firstText}>
            {t('trading_space_banner_text')}
          </div>
          <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.firstStart, 'btn-default'].join(' ')}>{t('start_earning')}</a>
        </div>
      </div>
    </div>
  );
};

export default First;
