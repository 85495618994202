import ConditionsPage from '../../pages/ConditionsPage/ConditionsPage';
import MainPage from '../../pages/MainPage/MainPage';
import AboutPage from '../../pages/AboutPage/AboutPage';
import TradingSpacePage from '../../pages/TradingSpacePage/TradingSpacePage';

export const routes = [
    { path: '/', element: MainPage },
    { path: '/trading-space', element: TradingSpacePage },
    { path: '/trading-conditions', element: ConditionsPage },
    { path: '/about', element: AboutPage },
];

export const headerRoutes = [
    { path: '/trading-space', text: 'trading_space' },
    { path: '/trading-conditions', text: 'trading_conditions' },
    { path: '/about', text: 'about_us' },
];
