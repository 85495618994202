import React from "react";
import classes from "./Invest.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Invest = () => {
  const { t } = useTranslation();
  const defaultOption = Cookies.get('i18next');
  return (
    <div className={classes.invest}>
      <div className={classes.investBody}>
        <div className="container">
          <div className={classes.investTitle}>
            {t('trading_conditions_banner_title')}
          </div>
          <div className={classes.investSubtitle}>
            {t('trading_conditions_banner_text')}
          </div>
          <a href={`https://user.apexplatform.info/signup?lang=${defaultOption}`} className={[classes.investStartBtn, 'btn-default'].join(' ')}>{t('start_trading')}</a>
        </div>
      </div>
    </div>
  );
};

export default Invest;
