import React from 'react';
import classes from './Banner.module.scss';
import apple from '../../../assets/img/MainPage/banner_apple.png';
import tesla from '../../../assets/img/MainPage/banner_tesla.png';
import btc from '../../../assets/img/MainPage/banner_btc.png';
import eth from '../../../assets/img/MainPage/banner_eth.png';
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Cookies from "js-cookie";

const Banner = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className='container'>
            <div className={classes.banner}>
                <div className={classes.content}>
                    <div id='dsa' className={`${classes.header} ${lang !== 'en' && classes.headerRu} header-default`}>
                        <Trans
                            components={{ span: <span></span> }}
                        >{t('home_banner_header')}</Trans>
                    </div>
                    <div className={classes.text}>
                        {t('home_banner_text')}
                    </div>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.btn, 'btn-default'].join(' ')}>
                        {t('start_earning')}
                    </a>
                    <div className={classes.desc}>
                        {t('home_banner_takes_only')}
                    </div>
                    <img className={[classes.apple, 'no-select'].join(' ')} src={apple} alt='' />
                    <img className={[classes.tesla, 'no-select'].join(' ')} src={tesla} alt='' />
                    <img className={[classes.btc, 'no-select'].join(' ')} src={btc} alt='' />
                    <img className={[classes.eth, 'no-select'].join(' ')} src={eth} alt='' />
                </div>
            </div>
        </div>
    );
};

export default Banner;
