import React from "react";
import classes from './Instruments.module.scss';
import icon_01 from '../../../assets/img/MainPage/instruments_01.svg';
import icon_02 from '../../../assets/img/MainPage/instruments_02.svg';
import icon_03 from '../../../assets/img/MainPage/instruments_03.svg';
import icon_04 from '../../../assets/img/MainPage/instruments_04.svg';
import { useTranslation } from "react-i18next";

const Instruments = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.instruments}>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    {t('main_instruments_title')}
                </div>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_01} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('main_instruments_01_title')}
                        </div>
                        <div className={[classes.cardText, 'text-default'].join(' ')}>
                            {t('main_instruments_01_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_02} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('main_instruments_02_title')}
                        </div>
                        <div className={[classes.cardText, 'text-default'].join(' ')}>
                            {t('main_instruments_02_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_03} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('main_instruments_03_title')}
                        </div>
                        <div className={[classes.cardText, 'text-default'].join(' ')}>
                            {t('main_instruments_03_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_04} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('main_instruments_04_title')}
                        </div>
                        <div className={[classes.cardText, 'text-default'].join(' ')}>
                            {t('main_instruments_04_text')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instruments;
