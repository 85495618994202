import React from "react";
import classes from './OurBenefits.module.scss';
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Cookies from 'js-cookie';

const OurBenefits = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className="container">
            <div className={classes.benefits}>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    {t('benefits_title')}
                </div>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.cardWr}></div>
                        <div className={classes.content}>
                            <div className={classes.cardHeader}>
                                <Trans>
                                    {t('home_starttrade_01_title')}
                                </Trans>
                            </div>
                            <div className={classes.cardText}>
                                {t('home_starttrade_01_text')}
                            </div>
                            <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.cardBtn, 'btn-default'].join(' ')}>
                                {t('start_trading')}
                            </a>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardWr}></div>
                        <div className={classes.content}>
                            <div className={classes.cardHeader}>
                                <Trans>
                                    {t('home_starttrade_03_title')}
                                </Trans>
                            </div>
                            <div className={classes.cardText}>
                                {t('home_starttrade_03_text')}
                            </div>
                            <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.cardBtn, 'btn-default'].join(' ')}>
                                {t('buy_crypto_right_now')}
                            </a>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardWr}></div>
                        <div className={classes.content}>
                            <div className={classes.cardHeader}>
                                <Trans>{t('home_starttrade_02_title')}</Trans>
                            </div>
                            <div className={classes.cardText}>
                                {t('home_starttrade_02_text')}
                            </div>
                            <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.cardBtn, 'btn-default'].join(' ')}>
                                {t('invest_now')}
                            </a>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardWr}></div>
                        <div className={classes.content}>
                            <div className={classes.cardHeader}>
                                <Trans>{t('home_starttrade_04_title')}</Trans>
                            </div>
                            <div className={classes.cardText}>
                                {t('home_starttrade_04_text')}
                            </div>
                            <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={[classes.cardBtn, 'btn-default'].join(' ')}>
                                {t('open_demo_account')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurBenefits;
