import React, { useEffect, useRef } from "react";
import Banner from "../../components/MainPage/Banner/Banner";
import OutPlatform from "../../components/MainPage/OutPlatform/OutPlatform";
import OurBenefits from "../../components/MainPage/OurBenefits/OurBenefits";
import WhatDiffers from "../../components/MainPage/WhatDiffers/WhatDiffers";
import ShortOverview from "../../components/MainPage/ShortOverview/ShortOverview";
import Instruments from "../../components/MainPage/Instruments/Instruments";
import Table from "../../components/MainPage/Table/Table";
import { useLocation } from "react-router-dom";
import classes from "./MainPage.module.scss";
import AccountTypes from "../../components/MainPage/AccountTypes/AccountTypes";
import OurTraders from "../../components/MainPage/OurTraders/OurTraders";
import ReferFriend from "../../components/MainPage/ReferFriend/ReferFriend";
import Study from "../../components/MainPage/Study/Study";

const MainPage = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    // if (ref !== null && document.documentElement.clientWidth > 1200) {
    //   initAnimation(ref, 9);
    // }
  }, []);
  return (
    <div ref={ref} className="ovf-hidden">
      <div className={classes.gradient}>
        <div className={classes.light}></div>
        <Banner />
        <div id="animBlock_0">
          <OutPlatform />
        </div>
        <div id="animBlock_1">
          <OurBenefits />
        </div>
      </div>
      <div id="animBlock_2">
        <AccountTypes />
      </div>
      <div>
        <Study id="animBlock_9" />
      </div>
      <div id="animBlock_8">
        <ReferFriend />
      </div>
      <div id="animBlock_3">
        <WhatDiffers />
      </div>
      <div id="animBlock_4">
        <ShortOverview />
      </div>
      <div id="animBlock_5">
        <Instruments />
      </div>
      <div id="animBlock_6">
        <Table />
      </div>
      <div id="animBlock_7">
        <OurTraders />
      </div>
    </div>
  );
};

export default MainPage;
