import React, { useRef, useState } from "react";
import classes from "./OurTraders.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import "./OurTraders.scss";
import img1 from "../../../assets/img/MainPage/OurTraders/1.png";
import img2 from "../../../assets/img/MainPage/OurTraders/2.png";
import img3 from "../../../assets/img/MainPage/OurTraders/3.png";
import img4 from "../../../assets/img/MainPage/OurTraders/4.png";
import img5 from "../../../assets/img/MainPage/OurTraders/5.png";

import imgTitle from "../../../assets/img/MainPage/OurTraders/title.png";

const OurTraders = () => {
  const columns = [
    {
      id: 1,
      icon: img1,
      rate: 85,
      title: "Jeremy D",
      text: "“I’ve been with this broker from their very beginnin’ and I succeeded many times. So i recommend ApexPlatform to every trader. my advice is to trade crypto. it’s very significant for at this broker you’ll find the best conditions”",
    },
    {
      id: 2,
      icon: img2,
      rate: 100,
      title: "Leila Silva",
      text: "“My cousin recommended me to sign in at ApexPlatform, and I have never regretted it. Thanks god, I succeeded. The assets available for trading at this broker are outstanding. Also, I liked I can withdraw funds in the short period.”",
    },
    {
      id: 3,
      icon: img3,
      rate: 89,
      title: "Dinara Werianska",
      text: "“ApexPlatform is super: The customer service is quick to respond, deposits reflect immediately, withdrawals are quicker, no slippage. Infact, I am much more comfortable with them. Thank you ApexPlatform. May God continue to bless you!”",
    },
    {
      id: 4,
      icon: img4,
      rate: 95,
      title: "Li Chang",
      text: "“I am trading with a small account, so I can say that ApexPlatform's service is very good. Includes stable spreads, fast support, instant deposits, and withdrawals. Support software is also diverse and complete.”",
    },
    {
      id: 5,
      icon: img5,
      rate: 79,
      title: "John W.",
      text: "“I have been using ApexPlatform from june this year and i haven't got any issues. Deposits, withdrwals, transfers and their trading terminal too. its all good so far. And there customer care also replys fast. This is not one of those marketing whatevers. personal expereince. I recommend this platform”",
    },
  ];

  const [my_swiper] = useState({});
  const myRef = useRef(null);

  return (
    <div className={classes.traders}>
      <div className={classes.tradersBody}>
        <div className={classes.tradersTitle}>
          <img src={imgTitle} alt="" />
          Trustpilot
        </div>
        <div className={[classes.tradersSubtitle, "header-default"].join(" ")}>
          What Our Traders Think of Us
        </div>
        <div className={classes.tradersSlider}>
          <div
            id="myTradersSlider"
            onMouseEnter={() => {
              my_swiper.current.autoplay.stop();
            }}
            onMouseLeave={() => {
              my_swiper.current.autoplay.start();
            }}
          >
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              pagination={{ clickable: true, el: myRef.current }}
              spaceBetween={40}
              slidesPerView={4}
              loop={true}
              onBeforeInit={(swiper) => {
                my_swiper.current = swiper;
              }}
              speed={1000}
              autoplay={{ delay: 1000 }}
              breakpoints={{
                992: {
                  spaceBetween: 40,
                },
                480: {
                  spaceBetween: 20,
                },
                100: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
              }}
            >
              {columns.map((i) => {
                let resStars = "";
                if (i.rate <= 20) {
                  resStars = classes.tradersSliderSlideItemTopStarsProgress1;
                } else if (i.rate <= 40) {
                  resStars = classes.tradersSliderSlideItemTopStarsProgress2;
                } else if (i.rate <= 60) {
                  resStars = classes.tradersSliderSlideItemTopStarsProgress3;
                } else if (i.rate <= 80) {
                  resStars = classes.tradersSliderSlideItemTopStarsProgress4;
                } else if (i.rate <= 100) {
                  resStars = classes.tradersSliderSlideItemTopStarsProgress5;
                }
                return (
                  <SwiperSlide key={i.id}>
                    <div className={classes.tradersSliderSlide}>
                      <div className={classes.tradersSliderSlideItem}>
                        <div className={classes.tradersSliderSlideItemTop}>
                          <div
                            className={classes.tradersSliderSlideItemTopIcon}
                          >
                            <img src={i.icon} alt="" />
                          </div>
                          <div
                            className={classes.tradersSliderSlideItemTopTitle}
                          >
                            {i.title}
                          </div>
                          <div
                            className={classes.tradersSliderSlideItemTopStars}
                          >
                            <div
                              className={[
                                classes.tradersSliderSlideItemTopStarsProgress,
                                resStars,
                              ].join(" ")}
                              style={{ width: i.rate + "%" }}
                            ></div>
                            <div
                              className={
                                classes.tradersSliderSlideItemTopStarsStar
                              }
                            ></div>
                            <div
                              className={
                                classes.tradersSliderSlideItemTopStarsStar
                              }
                            ></div>
                            <div
                              className={
                                classes.tradersSliderSlideItemTopStarsStar
                              }
                            ></div>
                            <div
                              className={
                                classes.tradersSliderSlideItemTopStarsStar
                              }
                            ></div>
                            <div
                              className={
                                classes.tradersSliderSlideItemTopStarsStar
                              }
                            ></div>
                          </div>
                        </div>
                        <div className={classes.tradersSliderSlideItemText}>
                          {i.text}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="swiper-pagination1" ref={myRef}></div>
        </div>
      </div>
    </div>
  );
};

export default OurTraders;
