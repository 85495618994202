import React from "react";
import classes from "./Indices.module.scss";
import img1 from "../../../assets/img/TradingSpacePage/2/1.svg";
import img2 from "../../../assets/img/TradingSpacePage/2/2.svg";
import img3 from "../../../assets/img/TradingSpacePage/2/3.svg";
import img4 from "../../../assets/img/TradingSpacePage/2/4.svg";
import { useTranslation } from "react-i18next";

const Indices = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.indices}>
      <div className={classes.indicesBody}>
        <div className="container">
          <div className={[classes.indicesTitle, "title"].join(" ")}>
            {t('trade_forex_title')}
          </div>
          <div className={classes.indicesRow}>
            <div className={classes.indicesRowColumn}>
              <div className={classes.indicesRowColumnWrap}>
                <div className={classes.indicesRowColumnIcon}>
                  <img src={img1} alt="" />
                </div>
                <div className={classes.indicesRowColumnTitle}>
                  {t('trade_forex_01_title')}
                </div>
                <div className={classes.indicesRowColumnSubtitle}>
                  {t('trade_forex_01_text')}
                </div>
              </div>
            </div>
            <div className={classes.indicesRowColumn}>
              <div className={classes.indicesRowColumnWrap}>
                <div className={classes.indicesRowColumnIcon}>
                  <img src={img2} alt="" />
                </div>
                <div className={classes.indicesRowColumnTitle}>
                  {t('trade_forex_02_title')}
                </div>
                <div className={classes.indicesRowColumnSubtitle}>
                  {t('trade_forex_02_text')}
                </div>
              </div>
            </div>
            <div className={classes.indicesRowColumn}>
              <div className={classes.indicesRowColumnWrap}>
                <div className={classes.indicesRowColumnIcon}>
                  <img src={img3} alt="" />
                </div>
                <div className={classes.indicesRowColumnTitle}>
                  {t('trade_forex_03_title')}
                </div>
                <div className={classes.indicesRowColumnSubtitle}>
                  {t('trade_forex_03_text')}
                </div>
              </div>
            </div>
            <div className={classes.indicesRowColumn}>
              <div className={classes.indicesRowColumnWrap}>
                <div className={classes.indicesRowColumnIcon}>
                  <img src={img4} alt="" />
                </div>
                <div className={classes.indicesRowColumnTitle}>
                  {t('trade_forex_04_title')}
                </div>
                <div className={classes.indicesRowColumnSubtitle}>
                  {t('trade_forex_04_text')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indices;
