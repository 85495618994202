import React from "react";
import classes from './ReferFriend.module.scss';
import image_01 from '../../../assets/img/MainPage/ReferFriend/image_01.png';
import image_02 from '../../../assets/img/MainPage/ReferFriend/image_02.png';
import image_03 from '../../../assets/img/MainPage/ReferFriend/image_03.png';
import { useTranslation } from "react-i18next";

const cards = [
    {
        text_01: 'new_refer_friend_card_01_text_01',
        text_02: 'new_refer_friend_card_01_text_02',
        text_03: 'new_refer_friend_card_01_text_03',
        text_04: 'new_refer_friend_card_01_text_04',
        image: image_01,
    },
    {
        text_01: 'new_refer_friend_card_02_text_01',
        text_02: 'new_refer_friend_card_02_text_02',
        text_03: 'new_refer_friend_card_02_text_03',
        text_04: 'new_refer_friend_card_02_text_04',
        image: image_02,
    },
    {
        text_01: 'new_refer_friend_card_03_text_01',
        text_02: 'new_refer_friend_card_03_text_02',
        text_03: 'new_refer_friend_card_03_text_03',
        text_04: 'new_refer_friend_card_03_text_04',
        image: image_03,
    },
];

const ReferFriend = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.referFriend}>
                <div className={classes.header}>
                    <h2 className={[classes.title, 'header-default'].join(' ')}>
                        {t('new_refer_friend_title')}
                    </h2>
                    <h3 className={classes.subtitle}>
                        {t('new_refer_friend_subtitle')}
                    </h3>
                    <p className={[classes.text_01, 'text-default'].join(' ')}>
                        {t('new_refer_friend_text_01')}
                    </p>
                    <p className={classes.text_02}>
                        {t('new_refer_friend_text_02')}
                    </p>
                    <p className={[classes.text_03, 'text-default'].join(' ')}>
                        {t('new_refer_friend_text_03')}
                    </p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <div className={classes.cardText}>
                                <p className={[classes.cardText_01, 'text-default'].join(' ')}>
                                    {t(card.text_01)}
                                </p>
                                <p className={classes.cardText_02}>
                                    {t(card.text_02)}
                                </p>
                                <p className={classes.cardText_03}>
                                    {t(card.text_03)}
                                </p>
                                <p className={classes.cardText_04}>
                                    {t(card.text_04)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.desc}>
                    {t('new_refer_friend_desc')}
                </div>
            </div>
        </section>
    );
};

export default ReferFriend;
