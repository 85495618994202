import React, { useEffect } from "react";
import First from "../../components/TradingPage/First/First";
import Have from "../../components/TradingPage/Have/Have";
import Indices from "../../components/TradingPage/Indices/Indices";
import Motivation from "../../components/TradingPage/Motivation/Motivation";
import Order from "../../components/TradingPage/Order/Order";
import Short from "../../components/TradingPage/Short/Short";
import { useLocation } from "react-router-dom";

const TradingSpacePage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);
  return (
    <div className="ovf-hidden">
      <First />
      <Indices />
      <Have />
      <Short />
      <Order />
      <Motivation />
    </div>
  );
};

export default TradingSpacePage;
