import React, { useState } from "react";
import classes from "./Motivation.module.scss";
import img1 from "../../../assets/img/TradingSpacePage/6/1.png";
import img2 from "../../../assets/img/TradingSpacePage/6/2.png";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Motivation = () => {
  const { t } = useTranslation();
  const [hovAnim, setHovAnim] = useState(false);
  const defaultOption = Cookies.get('i18next');
  return (
    <div className={classes.motivation}>
      <div className={classes.motivationBody}>
        <div className="container">
          <div className={classes.motivationWrap}>
            <div className={[classes.motivationTitle, "title"].join(" ")}>
              {t('fast_ordering_title_03')}
            </div>
            <div className={classes.motivationSubtitle}>
              {t('fast_ordering_text_07')}
            </div>
            <div className={classes.motivationText}>
              {t('fast_ordering_text_08')}
            </div>
            <a
              href={`https://user.apexplatform.info/signup?lang=${defaultOption}`}
              className={[classes.motivationStart, 'btn-default'].join(' ')}
              onMouseEnter={() => {
                console.log();
                if (window.innerWidth > 992) {
                  setHovAnim(true);
                }
              }}
              onMouseLeave={() => {
                if (window.innerWidth > 992) {
                  setHovAnim(false);
                }
              }}
            >
              {t('start_earning')}
            </a>
            <div
              className={
                hovAnim
                  ? [classes.motivationFirstImg, classes.active].join(" ")
                  : classes.motivationFirstImg
              }
            >
              <img src={img1} alt="" />
            </div>
            <div
              className={
                hovAnim
                  ? [classes.motivationSecondImg, classes.active].join(" ")
                  : classes.motivationSecondImg
              }
            >
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Motivation;
