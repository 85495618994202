import React, { useState } from "react";
import classes from './WhatDiffers.module.scss';
import icon_01 from '../../../assets/img/MainPage/differs_01.png';
import icon_02 from '../../../assets/img/MainPage/differs_02.png';
import icon_03 from '../../../assets/img/MainPage/differs_03.png';
import { useTranslation } from "react-i18next";

const cardContent = {
    less: 'what_differs_01_text_less',
    more: 'what_differs_01_text',
};

const WhatDiffers = () => {
    const { t } = useTranslation();
    const [ content, setContent ] = useState(cardContent.less);
    const [ option, setOption ] = useState('less');
    const newContent = () => {
        if (option === 'less') {
            setOption('more');
            setContent(cardContent.more);
            return;
        }
        setOption('less');
        setContent(cardContent.less);
    };
    return (
        <div className="container">
            <div className={classes.whatDiffers}>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    {t('what_differs')}
                </div>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_01} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('what_differs_01_title')}
                        </div>
                        <div className={classes.cardContent}>
                            {t(content)}
                        </div>
                        <div 
                            className={classes.btn}
                            onClick={newContent}
                        >
                            {option === 'less' ? 'Read more' : 'Read less'}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_02} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('what_differs_02_title')}
                        </div>
                        <div className={classes.cardContent}>
                            {t('what_differs_02_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardIcon}>
                            <img src={icon_03} alt='' />
                        </div>
                        <div className={classes.cardHeader}>
                            {t('what_differs_03_title')}
                        </div>
                        <div className={classes.cardContent}>
                            {t('what_differs_03_text')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatDiffers;
