import React from "react";
import classes from './OurBenefits.module.scss';
import icon_01 from '../../../assets/img/AboutPage/benefits_01.svg';
import icon_02 from '../../../assets/img/AboutPage/benefits_02.svg';
import icon_03 from '../../../assets/img/AboutPage/benefits_03.svg';
import icon_04 from '../../../assets/img/AboutPage/benefits_04.svg';
import icon_05 from '../../../assets/img/AboutPage/benefits_05.svg';
import icon_06 from '../../../assets/img/AboutPage/benefits_06.svg';
import icon_07 from '../../../assets/img/AboutPage/benefits_07.svg';
import icon_08 from '../../../assets/img/AboutPage/benefits_08.svg';
import icon_09 from '../../../assets/img/AboutPage/benefits_09.svg';
import { useTranslation } from "react-i18next";

const cards = [
    {
       image: icon_01,
       header: 'benefits_card_title_01',
       text: 'benefits_card_text_01',
    },
    {
        image: icon_02,
        header: 'benefits_card_title_02',
        text: 'benefits_card_text_02',
    },
    {
        image: icon_03,
        header: 'benefits_card_title_03',
        text: 'benefits_card_text_03',
    },
    {
        image: icon_04,
        header: 'benefits_card_title_04',
        text: 'benefits_card_text_04',
    },
    {
        image: icon_05,
        header: 'benefits_card_title_05',
        text: 'benefits_card_text_05',
    },
    {
        image: icon_06,
        header: 'benefits_card_title_06',
        text: 'benefits_card_text_06',
    },
    {
        image: icon_07,
        header: 'benefits_card_title_07',
        text: 'benefits_card_text_07',
    },
    {
        image: icon_08,
        header: 'benefits_card_title_08',
        text: 'benefits_card_text_08',
    },
    {
        image: icon_09,
        header: 'benefits_card_title_09',
        text: 'benefits_card_text_09',
    },
];

const OurBenefits = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.outBenfits}>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    {t('benefits_title')}
                </div>
                <div className={classes.text}>
                    {t('benefits_text')}
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div className={classes.card} key={index}>
                            <div className={classes.cardImage}>
                                <img src={card.image} alt=''/>
                            </div>
                            <div className={classes.cardHeader}>
                                {t(card.header)}
                            </div>
                            <div className={[classes.cardText, 'text-default'].join(' ')}>
                                {t(card.text)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurBenefits;
