import React from "react";
import classes from './Table.module.scss';
import btc from '../../../assets/img/MainPage/table_btc.svg';
import eth from '../../../assets/img/MainPage/table_eth.svg';
import usd from '../../../assets/img/MainPage/table_usd.svg';
import usdt from '../../../assets/img/MainPage/table_usdt.svg';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const rows = [
    {
        image: 'btc',
        coin: 'BitCoin (BTC)',
        apy: '3,2 %',
        term: 'table_coins_term_flexible'
    },
    {
        coin: 'Ether (ETH)',
        apy: '2,5 %',
        term: 'table_coins_term_flexible'
    },
    {
        coin: 'USD Tether (USDT)',
        apy: '0,51 %',
        term: 'table_coins_term_flexible'
    },
    {
        coin: 'USD Coin (USD)',
        apy: '2,06 %',
        term: 'table_coins_term_flexible'
    },
];

const Table = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className="container">
            <div className={classes.tableBlock}>
                <table>
                    <tbody>
                        <tr>
                            <td> </td>
                            <td>{t('table_coins_coin')}</td>
                            <td>{t('table_coins_apy')}</td>
                            <td>{t('table_coins_term')}</td>
                            <td> </td>
                        </tr>
                        {rows.map((row, index) =>
                            <tr className={index % 2 === 0 ? classes.bgGrey : undefined} key={index}>
                                <td>
                                    <img
                                        src={index === 0 ? btc : index === 1 ? eth : index === 2 ? usdt : usd}
                                        alt=''
                                    />
                                </td>
                                <td>{row.coin}</td>
                                <td>{row.apy}</td>
                                <td>{t(row.term)}</td>
                                <td>
                                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className={'btn-default'}>{t('start_earning')}</a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className={classes.btn}>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className="btn-default">{t('start_earning')}</a>
                </div>
            </div>
        </div>
    );
};

export default Table;
