import React from "react";
import classes from "./Short.module.scss";
import { useTranslation } from "react-i18next";

const Short = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.short}>
      <div className={classes.shortBody}>
        <div className="container">
          <div className={[classes.shortTitle, "title"].join(" ")}>
            {t('short_overview_title')}
          </div>
          <div className={classes.shortSubtitle}>
            {t('signals_for_traders_text')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Short;
