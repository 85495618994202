import React from "react";
import classes from "./Order.module.scss";
import img from "../../../assets/img/TradingSpacePage/5/arrow.png";
import img1 from "../../../assets/img/TradingSpacePage/5/1.png";
import img2 from "../../../assets/img/TradingSpacePage/5/2.png";
import { useTranslation } from "react-i18next";

const Order = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.order}>
      <div className={classes.orderBody}>
        <div className="container">
          <div className={[classes.orderTitle, "title"].join(" ")}>
            {t('fast_ordering_title_01')}
          </div>
          <div className={classes.orderSubtitle}>
            {t('fast_ordering_text_01')}
          </div>
          <div className={classes.example}>
            <div className={classes.exampleTitle}>{t('fast_ordering_title_02')}</div>
            <div className={classes.exampleRow}>
              <div className={classes.exampleRowLeft}>
                <div className={classes.exampleRowLeftText}>
                  {t('fast_ordering_text_02')}
                </div>
                <div className={classes.exampleRowLeftImg}>
                  <img src={img} alt="" />
                </div>
                <div className={classes.exampleRowLeftText}>
                  {t('fast_ordering_text_02_bottom')}
                </div>
              </div>
              <div className={classes.exampleRowRight}>
                <div className={classes.exampleRowRightText}>
                  {t('fast_ordering_text_03')}
                </div>
                <div className={classes.exampleRowRightText}>
                  {t('fast_ordering_text_04')} {t('fast_ordering_text_05')}
                </div>
              </div>
            </div>
            <div className={classes.exampleRowSubtext}>
              {t('fast_ordering_text_06')}
            </div>
            <div className={classes.exampleFirstImg}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.exampleSecondImg}>
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
