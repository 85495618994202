import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.svg";
import { headerRoutes } from "../../../config/routes/routes";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import $ from 'jquery';

const languages = [
  { value: "ru", label: "Ru" },
  { value: "en", label: "En" },
  { value: "es", label: "Es" },
  { value: "it", label: "It" },
  { value: "de", label: "De" },
  { value: "pl", label: "Pl" },
];

const Header = () => {
  const { t } = useTranslation();
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));
  const [burgerA, setBurgerA] = useState(false);
  const [numNav, setNumNav] = useState(0);
  const { pathname } = useLocation();
  const lang = Cookies.get('i18next');
  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  useEffect(() => {
    setBurgerA(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerA) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerA]);
  window.addEventListener("resize", (event) => {
    setBurgerA(false);
  });
  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link to="/" onClick={() => setBurgerA(false)}>
                <img src={logo} alt="" />
              </Link>
            </div>
            <div
              className={
                burgerA
                  ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                  : classes.headerRowCenterWrapper
              }
            >
              <div
                className={[classes.headerRowCenter, `navact${numNav}`].join(
                  " "
                )}
              >
                {headerRoutes.map((i, idx) => (
                  <div key={i.text}>
                    <Link
                      to={i.path}
                      className={classes.headerRowCenterItem}
                      onClick={() => {
                        setBurgerA(false);
                        setNumNav(idx + 1);
                      }}
                    >
                      {t(i.text)}
                    </Link>
                  </div>
                ))}
                <div className={classes.btnRow}>
                  {" "}
                  <a
                    className={
                      burgerA
                        ? [
                          classes.headerRowRightLogBtn,
                          classes.active,
                          classes.mob,
                        ].join(" ")
                        : classes.headerRowRightLogBtn
                    }
                    href={`https://user.apexplatform.info/login?lang=${lang}`}
                  >
                    {t("login")}
                  </a>
                  <a
                    className={
                      burgerA
                        ? [
                          classes.headerRowRightSignUpBtn,
                          classes.active,
                          classes.mob,
                        ].join(" ")
                        : classes.headerRowRightSignUpBtn
                    }
                    href={`https://user.apexplatform.info/signup?lang=${lang}`}
                  >
                    {t("signup")}
                  </a>
                </div>
              </div>
            </div>
            <div className={classes.headerRowRight}>
              <div className={classes.headerRowRightLocalization}>
                <Dropdown
                  options={languages}
                  onChange={(e) => i18n.changeLanguage(e.value)}
                  value={defaultOption}
                  placeholder="EN"
                />
              </div>
              <a
                className={
                  burgerA
                    ? [classes.headerRowRightLogBtn, classes.active].join(" ")
                    : classes.headerRowRightLogBtn
                }
                href={`https://user.apexplatform.info/login?lang=${lang}`}
              >
                {t("login")}
              </a>
              <a
                className={
                  burgerA
                    ? [classes.headerRowRightSignUpBtn, classes.active].join(
                      " "
                    )
                    : classes.headerRowRightSignUpBtn
                }
                href={`https://user.apexplatform.info/signup?lang=${lang}`}
              >
                {t("signup")}
              </a>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  setBurgerA(!burgerA);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
