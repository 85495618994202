import React from "react";
import classes from './OutPlatform.module.scss';
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

const OutPlatform = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.outPlatform}>
                <div className={classes.light}></div>
                <div className={[classes.header, 'header-default'].join(' ')}>
                    <Trans
                        components={{ span: <span></span> }}
                    >{t('our_platforms_title')}</Trans>
                </div>
                <div className={classes.text}>
                    {t('our_platforms_text')}
                </div>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            ${t('our_platforms_01_num')}+
                        </div>
                        <div className={classes.cardText}>
                            {t('our_platforms_01_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            {t('our_platforms_02_num')}+
                        </div>
                        <div className={classes.cardText}>
                            {t('our_platforms_02_text')}
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            {t('our_platforms_03_num')}
                        </div>
                        <div className={classes.cardText}>
                            {t('our_platforms_03_text')}
                        </div>
                    </div>
                    {/* <div className={classes.card}>
                        <div className={classes.cardHeader}>
                            {t('our_platforms_04_num')}
                        </div>
                        <div className={classes.cardText}>
                            {t('our_platforms_04_text')}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default OutPlatform;
