import React from "react";
import classes from "./Industry.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const Industry = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  return (
    <div className={classes.industry}>
      <div className={classes.industryBody}>
        <div className="container">
          <div className={[classes.industryTitle, "title"].join(" ")}>
            {t('industry_title')}
          </div>
          <div className={classes.industrySubtitle}>
            {t('industry_text_01')} {t('industry_text_02')}
          </div>
          <div className={classes.industryRow}>
            <div className={classes.industryRowColumn}>
              <div className={classes.industryRowColumnItem}>
                <div className={classes.industryRowColumnItemTitle}>
                  {t('industry_card_01_title')}
                </div>
                <div className={classes.industryRowColumnItemBot}>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      EURUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 1.4
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      GBPUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 1.6
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      AUDUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 1.4
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      USDJPY
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 1.5
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItem1Start}>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className="btn-default">
                      {t('start_trading')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.industryRowColumn}>
              <div className={classes.industryRowColumnItem}>
                <div className={classes.industryRowColumnItemTitle}>
                  {t('industry_card_02_title')}
                </div>
                <div className={classes.industryRowColumnItemBot}>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      EURUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.0
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      GBPUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.5
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      AUDUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.3
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      USDJPY
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.4
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItem1Start}>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className="btn-default">
                      {t('start_trading')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.industryRowColumn}>
              <div className={classes.industryRowColumnItem}>
                <div className={classes.industryRowColumnItemTitle}>
                  {t('industry_card_03_title')}
                </div>
                <div className={classes.industryRowColumnItemBot}>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      EURUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.0
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      GBPUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.5
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      AUDUSD
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.3
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItemBotRow}>
                    <div className={classes.industryRowColumnItemBotRowLeft}>
                      USDJPY
                    </div>
                    <div className={classes.industryRowColumnItemBotRowRight}>
                      {t('industry__from')} 0.4
                    </div>
                  </div>
                  <div className={classes.industryRowColumnItem1Start}>
                    <a href={`https://user.apexplatform.info/signup?lang=${lang}`} className="btn-default">
                      {t('start_trading')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
