import logo from '../assets/img/header/logo.svg';
// main
import main_banner_img_01 from '../assets/img/MainPage/banner_eth.png';
import main_banner_img_02 from '../assets/img/MainPage/banner_btc.png';
import main_banner_img_03 from '../assets/img/MainPage/banner_light.png';
import main_banner_img_04 from '../assets/img/MainPage/banner_tesla.png';
import main_banner_img_05 from '../assets/img/MainPage/banner_apple.png';
// trading space 
import tr_space_img_01 from '../assets/img/TradingSpacePage/1/1.png';
import tr_space_img_02 from '../assets/img/TradingSpacePage/1/2.png';
// tr conditions
import tr_cond_img_01 from '../assets/img/ConditionsPage/1/1.png';
import tr_cond_img_02 from '../assets/img/ConditionsPage/1/2.png';
// about us
import about_banner_img_01 from '../assets/img/AboutPage/banner_bear.png';
import about_banner_img_02 from '../assets/img/AboutPage/banner_bull.png';
import about_banner_img_03 from '../assets/img/AboutPage/banner_light_01.png';
import about_banner_img_04 from '../assets/img/AboutPage/banner_light_02.png';
import about_banner_img_05 from '../assets/img/AboutPage/banner_together.png';

export const images = [
    logo,
    main_banner_img_01,
    main_banner_img_02,
    main_banner_img_03,
    main_banner_img_04,
    main_banner_img_05,
    tr_space_img_01,
    tr_space_img_02,
    tr_cond_img_01,
    tr_cond_img_02,
    about_banner_img_01,
    about_banner_img_02,
    about_banner_img_03,
    about_banner_img_04,
    about_banner_img_05,
];