import React, { useRef, useEffect } from "react";
import Banner from "../../components/AboutPage/Banner/Banner";
import OurBenefits from "../../components/AboutPage/OurBenefits/OurBenefits";
import WhoWeAre from "../../components/AboutPage/WhoWeAre/WhoWeAre";
import { initAnimation } from "../../mixins/animationMethods";
import { useLocation } from 'react-router-dom';

const AboutPage = () => {
    const ref = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
        document.body.scrollTop = 0;
    }, [pathname]);
    useEffect(() => {
        if (ref !== null && document.documentElement.clientWidth > 1200) {
            initAnimation(ref, 2);
        }
    }, []);
    return (
        <div ref={ref} className="ovf-hidden">
            <Banner />
            <div id='animBlock_0'>
                <WhoWeAre />
            </div>
            <div id='animBlock_1'>
                <OurBenefits />
            </div>
        </div>
    );
};

export default AboutPage;
